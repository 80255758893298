<template>
  <div class="content">
    <div id="allmap" ref="allmap"></div>
    <div class="box_btn">
      <el-button @click="go" size="small" type="primary" plain>返回</el-button>
    </div>
    <div class="box">
      <div class="box_tt">路径信息</div>
      <div class="box_1">
        <div v-for="(item, i) in urlLogList" :key="i">
          <div>地址：{{ item.address }}</div>
          <div>时间：{{ item.create_at }}</div>
        </div>
      </div>
      <el-pagination
        style="padding-top: 10px"
        small
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total3"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      urlLogList: [],
      total3: 0,
      page: 1,
    }
  },
 
  mounted() {
    console.log(11,this.$route.query.id)
    this.urlLog(this.$route.query.id)
  },
  methods: {
    map(list) {
      let map = new window.BMap.Map(this.$refs.allmap) // 创建Map实例
      map.addControl(new window.BMap.ScaleControl())
      map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      var opts = { anchor: BMAP_ANCHOR_TOP_RIGHT }
      map.addControl(new BMap.NavigationControl(opts))
      let points = []
      let marker = []
      let infoWindow

      list.forEach((e, i) => {
        // 创建point, 将x,y值传入
        points.push(new BMap.Point(e.lat - 0, e.lng - 0))
        opts = {
          width: 280,
          height: 100,
          title: '路径信息',
        }
        let infoWindow = new BMap.InfoWindow(
          '地址:&nbsp&nbsp' + e.address + '</br>' + '时间:&nbsp&nbsp' + e.create_at,
          opts
        )
        if (i == 0) {
          map.centerAndZoom(points[i], 11) // 初始化地图,设置中心点坐标和
          //第一个点加载起点坐标
          var point = new window.BMap.Point(list[0].lat - 0, list[0].lng - 0)
          var iconImg = new window.BMap.Icon(
            require('../../assets/img/icon08.png'),
            new BMap.Size(48, 48)
          )
          var markerBegin = new window.BMap.Marker(point, { icon: iconImg })

          map.addOverlay(markerBegin)
          markerBegin.addEventListener('click', function (e) {
          console.log(e)
            map.openInfoWindow(infoWindow, point); // 开启信息窗口
        });
        } else if (i === list.length - 1) {
          //最后一个点加载起终点坐标
          console.log(list.length - 1)
          var point = new window.BMap.Point(
            list[list.length - 1].lat - 0,
            list[list.length - 1].lng - 0
          )
          var iconImg = new window.BMap.Icon(
            require('/src/assets/img/icon09.png'),
            new BMap.Size(48, 48)
          )
          var markerEnd = new window.BMap.Marker(point, { icon: iconImg })

          map.addOverlay(markerEnd)
          markerEnd.addEventListener('click', function (e) {
          console.log(e)
            map.openInfoWindow(infoWindow, point); // 开启信息窗口
        });
        } else {
          // 创建点标记
          var point = new BMap.Point(e.lat - 0, e.lng - 0)
          marker[i] = new BMap.Marker(point)
          map.addOverlay(marker[i])
          marker[i].addEventListener('click', function (e) {
          console.log(e)
            map.openInfoWindow(infoWindow, point); // 开启信息窗口
        });
        }
        
      })
      let polyline = new window.BMap.Polyline(points, {
        strokeColor: 'blue',
        strokeWeight: 7,
        strokeOpacity: 0.7,
      })
      map.addOverlay(polyline)

    },
    handleCurrentChange(val) {
      this.page = val
      this.urlLog()
    },

    // 物流路径信息
    async urlLog(id) {
      const { data: res } = await this.$http.get('api/appear/urlLog', {
        params: {
          page: this.page,
          pageNumber: 8,
          id: id,
        },
      })
      console.log(11, res)
      this.urlLogList = res.data.rows
      this.total3 = res.data.total
      if (res.data.rows.length > 0) {
        this.map(res.data.rows)
      }
    },
    // 返回
    go() {
      this.$router.push('/out')
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
#allmap {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.box_btn {
  position: absolute;
  top: 10px;
  left: 60px;
}
.box {
  position: absolute;
  top: 60px;
  left: 60px;
  width: 400px;
  // height: 700px;
  background-color: #fff;
  z-index: 999999 !important;
  padding: 20px;
  box-sizing: border-box;
  .box_tt {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .box_1 {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
    > div {
      border-bottom: 1px solid #f3f1f1;
      padding: 10px 0;
    }
    > div:last-child {
      border-bottom: none;
    }
  }
}
</style>